<template>
  <a-modal v-model="visible" :title="title" :width="450" :maskClosable="false">
    <template slot="footer">
      <a-button @click="closeModal">Close</a-button>
      <a-button type="primary" @click="submitForm" :loading="loading">Confirm</a-button>
    </template>
    <a-form-model ref="form"
                  :model="form">
      <a-form-model-item label="Int value" prop="intValue" class="mb-15">
        <a-input-number v-model="form.intValue" placeholder="Enter value..." class="w-full" :min="0"/>
      </a-form-model-item>
      <a-form-model-item label="Description" prop="description" class="mb-15">
        <a-textarea v-model="form.description" placeholder="Enter description..." :rows="4"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import SystemConfigsService from '@/services/app/system-configs.service'

export default {
  name: "SystemConfigsForm",
  data() {
    return {
      visible: false,
      title: 'Edit system config',
      loading: false,
      form: {
        description: null,
        intValue: null
      },
      detail: null,
    }
  },
  methods: {
    showModal(item = null) {
      this.visible = true;
      this.detail = {...item}
      this.fillForm(item)
    },
    closeModal() {
      this.$refs.form.resetFields()
      this.visible = false
      this.detail = null
    },
    submitForm() {
      const payload = {...this.form}
      this.loading = true
      SystemConfigsService.update(this.detail.code, payload).then(res => {
        if (res.code === 1) {
          this.$notification.success({
            message: 'Edit config successfully'
          })
          this.closeModal()
          this.$emit('fetch')
        } else {
          this.$notification.warning({
            message: res && res.message ? res.message : 'Something went wrong',
          })
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$notification.error({
          message: err && err.message ? err.message : 'Something went wrong',
        })
      })
    },
    fillForm(data) {
      this.form.description = data.description;
      this.form.intValue = data.intValue;
    }
  }
}
</script>
